const awsmobile = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:163f0452-ad4a-415b-89c2-16b22b31a8c7',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_gPumEmBot',
  aws_user_pools_web_client_id: 'v1h1e1fvmomrkov9rhu0jlhmm',
  aws_appsync_graphqlEndpoint:
    'https://c4mrfmq2jbc7fpkiglxfu4tdo4.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-yvbq3jlehnh6foiq5nftkudk2u',
  aws_user_files_s3_bucket: 'datahubcontent84346-main',
  aws_user_files_s3_bucket_region: 'eu-west-1',
  aws_cognito_social_providers: ['GOOGLE'],
  federationTarget: 'COGNITO_USER_POOLS',
  oauth: {
    domain: 'data-hub-auth.gladior.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'http://localhost:3000,https://datahubapp.gladior.com/',
    redirectSignOut: 'http://localhost:3000/,https://datahubapp.gladior.com/',
    responseType: 'code',
  },
};

export default awsmobile;
