/* eslint-disable consistent-return */
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import filterGraphQLFields from '../utilities/filterGraphQLFields';

async function getAllQueryObjectsForDataSource(dataSourceId) {
  const excludeArray = [
    'owner',
    'editors',
    'admins',
    'createdAt',
    'updatedAt',
    'dataProvider',
    'displayName',
    'displayNameLowercase',
    'configParamsHash',
    'name',
    'nameLowercase',
    'tags',
    'tagsLowercase',
    'tagsLowercaseToString',
    'url',
    'hostname',
    'domain',
    'type',
    'view',
    'multipleViews',
    'dataUrls',

    'preCacheRequests',
    'notificationActions',
    'dataFreshness',
    'filter',
    'version',
    'rateLimit',

    'dataSourceCredentialId',
    'credential',

    'queries.items.integrations.items.credential.authData',
    'queries.items.integrations.items.credential.authDataId',
    'queries.items.integrations.items.credential.clientId',
    'queries.items.integrations.items.dataSource.queries',
    'queries.items.integrations.items.dataSource.dataUrls',
    'queries.items.integrations.items.dataSource.preCacheRequests',
    'queries.items.integrations.items.dataSource.notificationActions',
    'queries.items.integrations.items.dataSource.credential.authData',
    'queries.items.integrations.items.dataSource.credential.authDataId',
    'queries.items.integrations.items.dataSource.credential.clientId',
    'integrations',
    'queries.items.integrations.items.query',
    'queries.items.integrations.items.dataSource.integrations',
    'queries.items.credential',
    'queries.items.dataSource',
    'dataSourceTeamId',
    'team',
    'labels',
    'groups',
    'items.queryObjects',
  ];

  const getFilter = (path) => {
    const re = new RegExp(`getDataSource.(${excludeArray.join('|')})$`);
    return !re.test(path);
  };
  const query = filterGraphQLFields(queries.getDataSource, getFilter);
  const QO = await API.graphql({
    ...graphqlOperation(query, {
      id: dataSourceId,
      limit: 1000,
    }),
  });
  return QO.data.getDataSource.queries.items;
}

export default getAllQueryObjectsForDataSource;
