/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDataStudioUsage = /* GraphQL */ `
  query GetDataStudioUsage($dataSourceId: String, $teamId: String) {
    getDataStudioUsage(dataSourceId: $dataSourceId, teamId: $teamId) {
      lastViewedTimestamp
      lastConfiguredTimestamp
      accountCount
      accounts
      used
      viewed
      configured
      __typename
    }
  }
`;
export const userHasFreeEmailDomain = /* GraphQL */ `
  query UserHasFreeEmailDomain {
    userHasFreeEmailDomain
  }
`;
export const getAllTeamInvites = /* GraphQL */ `
  query GetAllTeamInvites {
    getAllTeamInvites {
      id
      userId
      inviteType
      adminId
      admin {
        id
        avatar {
          visibility
          identityId
          file {
            bucket
            key
            region
            fileName
            __typename
          }
          __typename
        }
        name
        email
        __typename
      }
      email
      status
      inviteLink {
        id
        teamId
        admin
        createdAt
        updatedAt
        __typename
      }
      teamId
      team {
        name
        avatar {
          visibility
          identityId
          file {
            bucket
            key
            region
            fileName
            __typename
          }
          __typename
        }
        emailDomain
        membersCount
        members {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          __typename
        }
        colorTheme {
          primaryColor
          secondaryColor
          __typename
        }
        __typename
      }
      permission
      createdAt
      updatedAt
      teamInviteInviteLinkId
      __typename
    }
  }
`;
export const getAllTeamMembers = /* GraphQL */ `
  query GetAllTeamMembers($teamId: String) {
    getAllTeamMembers(teamId: $teamId) {
      id
      name
      sub
      email
      avatar {
        visibility
        identityId
        file {
          bucket
          key
          region
          fileName
          __typename
        }
        __typename
      }
      permission
      memberStatus
      owner
      __typename
    }
  }
`;
export const getDataStudioTemplates = /* GraphQL */ `
  query GetDataStudioTemplates($teamId: String, $dataProviders: [String]) {
    getDataStudioTemplates(teamId: $teamId, dataProviders: $dataProviders) {
      id
      name
      connectorId
      reportTemplateId
      thumbnail
      __typename
    }
  }
`;
export const getTeamInvite = /* GraphQL */ `
  query GetTeamInvite($id: ID!) {
    getTeamInvite(id: $id) {
      id
      userId
      inviteType
      adminId
      admin {
        id
        avatar {
          visibility
          identityId
          file {
            bucket
            key
            region
            fileName
            __typename
          }
          __typename
        }
        name
        email
        __typename
      }
      email
      status
      inviteLink {
        id
        teamId
        admin
        createdAt
        updatedAt
        __typename
      }
      teamId
      team {
        name
        avatar {
          visibility
          identityId
          file {
            bucket
            key
            region
            fileName
            __typename
          }
          __typename
        }
        emailDomain
        membersCount
        members {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          __typename
        }
        colorTheme {
          primaryColor
          secondaryColor
          __typename
        }
        __typename
      }
      permission
      createdAt
      updatedAt
      teamInviteInviteLinkId
      __typename
    }
  }
`;
export const listTeamInvites = /* GraphQL */ `
  query ListTeamInvites(
    $filter: ModelTeamInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamInvites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        inviteType
        adminId
        admin {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          email
          __typename
        }
        email
        status
        inviteLink {
          id
          teamId
          admin
          createdAt
          updatedAt
          __typename
        }
        teamId
        team {
          name
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          emailDomain
          membersCount
          members {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            __typename
          }
          colorTheme {
            primaryColor
            secondaryColor
            __typename
          }
          __typename
        }
        permission
        createdAt
        updatedAt
        teamInviteInviteLinkId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvite = /* GraphQL */ `
  query GetInvite($id: ID!) {
    getInvite(id: $id) {
      id
      teamId
      admin
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      avatar {
        visibility
        identityId
        file {
          bucket
          key
          region
          fileName
          __typename
        }
        __typename
      }
      userType
      defaultTeamId
      userSettings {
        onboardingCompleted
        releaseNotes
        __typename
      }
      teams {
        items {
          id
          teamMembershipUserId
          user {
            id
            username
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            userType
            defaultTeamId
            userSettings {
              onboardingCompleted
              releaseNotes
              __typename
            }
            teams {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          teamMembershipTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        avatar {
          visibility
          identityId
          file {
            bucket
            key
            region
            fileName
            __typename
          }
          __typename
        }
        userType
        defaultTeamId
        userSettings {
          onboardingCompleted
          releaseNotes
          __typename
        }
        teams {
          items {
            id
            teamMembershipUserId
            user {
              id
              username
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              userType
              defaultTeamId
              userSettings {
                onboardingCompleted
                releaseNotes
                __typename
              }
              teams {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            teamMembershipTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            owner
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      avatar {
        visibility
        identityId
        file {
          bucket
          key
          region
          fileName
          __typename
        }
        __typename
      }
      name
      owner
      members
      admins
      editors
      emailDomains
      autoInviteDomains
      teamMembers {
        items {
          id
          teamMembershipUserId
          user {
            id
            username
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            userType
            defaultTeamId
            userSettings {
              onboardingCompleted
              releaseNotes
              __typename
            }
            teams {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          teamMembershipTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      dataSources {
        items {
          id
          owner
          editors
          admins
          createdAt
          updatedAt
          dataProvider
          displayName
          displayNameLowercase
          configParamsHash
          name
          nameLowercase
          tags
          tagsLowercase
          tagsLowercaseToString
          url
          hostname
          domain
          type
          view {
            viewId
            viewName
            propertyId
            websiteId
            loginCustomerId
            customerId
            site
            locationId
            channelId
            spreadsheetId
            accountId
            projectId
            dc
            connectorId
            dataSourceIds
            credentialIds
            merchantId
            isManager
            timeZone
            reportType
            url
            device
            type
            dataSourceId
            credentialId
            __typename
          }
          multipleViews {
            viewId
            viewName
            propertyId
            websiteId
            loginCustomerId
            customerId
            site
            locationId
            channelId
            spreadsheetId
            accountId
            projectId
            dc
            connectorId
            dataSourceIds
            credentialIds
            merchantId
            isManager
            timeZone
            reportType
            url
            device
            type
            dataSourceId
            credentialId
            __typename
          }
          dataUrls {
            items {
              id
              owner
              groups
              name
              nameLowercase
              status
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              createdAt
              updatedAt
              dataSourceDataUrlsId
              __typename
            }
            nextToken
            __typename
          }
          queries {
            items {
              id
              owner
              groups
              name
              nameLowercase
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              additionalFields {
                name
                value
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              queryTeamId
              createdAt
              updatedAt
              dataSourceQueriesId
              __typename
            }
            nextToken
            __typename
          }
          preCacheRequests {
            items {
              id
              name
              nameLowercase
              fields
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              requestCount
              query
              TTL
              updatedAt
              createdAt
              dataSourcePreCacheRequestsId
              __typename
            }
            nextToken
            __typename
          }
          notificationActions {
            items {
              id
              owner
              groups
              name
              nameLowercase
              status
              notificationService
              template
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              limit
              fields
              sort {
                id
                sortDirection
                __typename
              }
              dimensionsFilters {
                orFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                __typename
              }
              dateRangeType
              dateRange {
                startDate
                endDate
                __typename
              }
              schedule
              trigger
              notificationActionCredentialId
              createdAt
              updatedAt
              dataSourceNotificationActionsId
              __typename
            }
            nextToken
            __typename
          }
          integrations {
            items {
              id
              type
              dataProvider
              additionalSettings {
                name
                value
                __typename
              }
              query {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                queryTeamId
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              integrationCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              integrationDataSourceId
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              createdAt
              updatedAt
              composerQueryIntegrationsId
              owner
              __typename
            }
            nextToken
            __typename
          }
          dataFreshness
          filter {
            isActive
            dimensionsFilters {
              fieldName
              values
              type
              operator
              __typename
            }
            segment {
              id
              name
              __typename
            }
            __typename
          }
          version
          rateLimit {
            max
            interval
            __typename
          }
          dataSourceCredentialId
          credential {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          dataSourceTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          labels {
            items {
              id
              dataSourceID
              labelID
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              dataSourceLabelDataSourcesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          groups
          __typename
        }
        nextToken
        __typename
      }
      credentials {
        items {
          id
          name
          email
          picture
          status
          owners
          editors
          createdAt
          updatedAt
          credentialTeamId
          authType
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          dataProvider
          groups
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          credentialLabelId
          authDataId
          clientId
          authData {
            accessToken
            refreshToken
            scope
            tokenType
            idToken
            expiryDate
            username
            password
            token
            key
            environment
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      dataSourceLabels {
        items {
          id
          name
          nameLowercase
          value
          type
          dataSourceLabelTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          createdAt
          dataProviders
          dataSources {
            items {
              id
              dataSourceID
              labelID
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              dataSourceLabelDataSourcesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      defaultTemplateId
      workspaceType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        avatar {
          visibility
          identityId
          file {
            bucket
            key
            region
            fileName
            __typename
          }
          __typename
        }
        name
        owner
        members
        admins
        editors
        emailDomains
        autoInviteDomains
        teamMembers {
          items {
            id
            teamMembershipUserId
            user {
              id
              username
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              userType
              defaultTeamId
              userSettings {
                onboardingCompleted
                releaseNotes
                __typename
              }
              teams {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            teamMembershipTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            owner
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dataSources {
          items {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                queryTeamId
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          nextToken
          __typename
        }
        credentials {
          items {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        dataSourceLabels {
          items {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        defaultTemplateId
        workspaceType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDataSourceSet = /* GraphQL */ `
  query GetDataSourceSet($id: ID!) {
    getDataSourceSet(id: $id) {
      id
      name
      nameLowercase
      tags
      tagsLowercase
      tagsLowercaseToString
      createdAt
      updatedAt
      owner
      editors
      __typename
    }
  }
`;
export const listDataSourceSets = /* GraphQL */ `
  query ListDataSourceSets(
    $filter: ModelDataSourceSetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataSourceSets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        nameLowercase
        tags
        tagsLowercase
        tagsLowercaseToString
        createdAt
        updatedAt
        owner
        editors
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTemplate = /* GraphQL */ `
  query GetTemplate($id: ID!) {
    getTemplate(id: $id) {
      id
      owner
      editors
      admins
      createdAt
      updatedAt
      name
      nameLowercase
      description
      image {
        bucket
        key
        region
        __typename
      }
      pages {
        id
        name
        datasourceAliases {
          alias
          dataProvider
          dataSourceId
          __typename
        }
        image {
          bucket
          key
          region
          __typename
        }
        __typename
      }
      type
      category
      tags
      tagsLowercase
      tagsLowercaseToString
      dataProviders
      groups
      editGroups
      view {
        lookerStudioId
        datasourceAliases {
          alias
          dataProvider
          dataSourceId
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listTemplates = /* GraphQL */ `
  query ListTemplates(
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        editors
        admins
        createdAt
        updatedAt
        name
        nameLowercase
        description
        image {
          bucket
          key
          region
          __typename
        }
        pages {
          id
          name
          datasourceAliases {
            alias
            dataProvider
            dataSourceId
            __typename
          }
          image {
            bucket
            key
            region
            __typename
          }
          __typename
        }
        type
        category
        tags
        tagsLowercase
        tagsLowercaseToString
        dataProviders
        groups
        editGroups
        view {
          lookerStudioId
          datasourceAliases {
            alias
            dataProvider
            dataSourceId
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDataSource = /* GraphQL */ `
  query GetDataSource($id: ID!) {
    getDataSource(id: $id) {
      id
      owner
      editors
      admins
      createdAt
      updatedAt
      dataProvider
      displayName
      displayNameLowercase
      configParamsHash
      name
      nameLowercase
      tags
      tagsLowercase
      tagsLowercaseToString
      url
      hostname
      domain
      type
      view {
        viewId
        viewName
        propertyId
        websiteId
        loginCustomerId
        customerId
        site
        locationId
        channelId
        spreadsheetId
        accountId
        projectId
        dc
        connectorId
        dataSourceIds
        credentialIds
        merchantId
        isManager
        timeZone
        reportType
        url
        device
        type
        dataSourceId
        credentialId
        __typename
      }
      multipleViews {
        viewId
        viewName
        propertyId
        websiteId
        loginCustomerId
        customerId
        site
        locationId
        channelId
        spreadsheetId
        accountId
        projectId
        dc
        connectorId
        dataSourceIds
        credentialIds
        merchantId
        isManager
        timeZone
        reportType
        url
        device
        type
        dataSourceId
        credentialId
        __typename
      }
      dataUrls {
        items {
          id
          owner
          groups
          name
          nameLowercase
          status
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                queryTeamId
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          limit
          fields
          sort {
            id
            sortDirection
            __typename
          }
          dimensionsFilters {
            orFilters {
              fieldName
              values
              type
              operator
              __typename
            }
            __typename
          }
          dateRangeType
          dateRange {
            startDate
            endDate
            __typename
          }
          createdAt
          updatedAt
          dataSourceDataUrlsId
          __typename
        }
        nextToken
        __typename
      }
      queries {
        items {
          id
          owner
          groups
          name
          nameLowercase
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                queryTeamId
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          limit
          fields
          sort {
            id
            sortDirection
            __typename
          }
          dimensionsFilters {
            orFilters {
              fieldName
              values
              type
              operator
              __typename
            }
            __typename
          }
          dateRangeType
          dateRange {
            startDate
            endDate
            __typename
          }
          additionalFields {
            name
            value
            __typename
          }
          integrations {
            items {
              id
              type
              dataProvider
              additionalSettings {
                name
                value
                __typename
              }
              query {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                queryTeamId
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              integrationCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              integrationDataSourceId
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              createdAt
              updatedAt
              composerQueryIntegrationsId
              owner
              __typename
            }
            nextToken
            __typename
          }
          queryTeamId
          createdAt
          updatedAt
          dataSourceQueriesId
          __typename
        }
        nextToken
        __typename
      }
      preCacheRequests {
        items {
          id
          name
          nameLowercase
          fields
          dimensionsFilters {
            orFilters {
              fieldName
              values
              type
              operator
              __typename
            }
            __typename
          }
          dateRangeType
          dateRange {
            startDate
            endDate
            __typename
          }
          requestCount
          query
          TTL
          updatedAt
          createdAt
          dataSourcePreCacheRequestsId
          __typename
        }
        nextToken
        __typename
      }
      notificationActions {
        items {
          id
          owner
          groups
          name
          nameLowercase
          status
          notificationService
          template
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                queryTeamId
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          limit
          fields
          sort {
            id
            sortDirection
            __typename
          }
          dimensionsFilters {
            orFilters {
              fieldName
              values
              type
              operator
              __typename
            }
            __typename
          }
          dateRangeType
          dateRange {
            startDate
            endDate
            __typename
          }
          schedule
          trigger
          notificationActionCredentialId
          createdAt
          updatedAt
          dataSourceNotificationActionsId
          __typename
        }
        nextToken
        __typename
      }
      integrations {
        items {
          id
          type
          dataProvider
          additionalSettings {
            name
            value
            __typename
          }
          query {
            id
            owner
            groups
            name
            nameLowercase
            dataSource {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            limit
            fields
            sort {
              id
              sortDirection
              __typename
            }
            dimensionsFilters {
              orFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              __typename
            }
            dateRangeType
            dateRange {
              startDate
              endDate
              __typename
            }
            additionalFields {
              name
              value
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            queryTeamId
            createdAt
            updatedAt
            dataSourceQueriesId
            __typename
          }
          integrationCredentialId
          credential {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          integrationDataSourceId
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                queryTeamId
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          createdAt
          updatedAt
          composerQueryIntegrationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      dataFreshness
      filter {
        isActive
        dimensionsFilters {
          fieldName
          values
          type
          operator
          __typename
        }
        segment {
          id
          name
          __typename
        }
        __typename
      }
      version
      rateLimit {
        max
        interval
        __typename
      }
      dataSourceCredentialId
      credential {
        id
        name
        email
        picture
        status
        owners
        editors
        createdAt
        updatedAt
        credentialTeamId
        authType
        team {
          id
          avatar {
            visibility
            identityId
            file {
              bucket
              key
              region
              fileName
              __typename
            }
            __typename
          }
          name
          owner
          members
          admins
          editors
          emailDomains
          autoInviteDomains
          teamMembers {
            items {
              id
              teamMembershipUserId
              user {
                id
                username
                avatar {
                  visibility
                  identityId
                  __typename
                }
                userType
                defaultTeamId
                userSettings {
                  onboardingCompleted
                  releaseNotes
                  __typename
                }
                teams {
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              teamMembershipTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              owner
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          dataSources {
            items {
              id
              owner
              editors
              admins
              createdAt
              updatedAt
              dataProvider
              displayName
              displayNameLowercase
              configParamsHash
              name
              nameLowercase
              tags
              tagsLowercase
              tagsLowercaseToString
              url
              hostname
              domain
              type
              view {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              multipleViews {
                viewId
                viewName
                propertyId
                websiteId
                loginCustomerId
                customerId
                site
                locationId
                channelId
                spreadsheetId
                accountId
                projectId
                dc
                connectorId
                dataSourceIds
                credentialIds
                merchantId
                isManager
                timeZone
                reportType
                url
                device
                type
                dataSourceId
                credentialId
                __typename
              }
              dataUrls {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  limit
                  fields
                  dateRangeType
                  createdAt
                  updatedAt
                  dataSourceDataUrlsId
                  __typename
                }
                nextToken
                __typename
              }
              queries {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                nextToken
                __typename
              }
              preCacheRequests {
                items {
                  id
                  name
                  nameLowercase
                  fields
                  dateRangeType
                  requestCount
                  query
                  TTL
                  updatedAt
                  createdAt
                  dataSourcePreCacheRequestsId
                  __typename
                }
                nextToken
                __typename
              }
              notificationActions {
                items {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  status
                  notificationService
                  template
                  limit
                  fields
                  dateRangeType
                  schedule
                  trigger
                  notificationActionCredentialId
                  createdAt
                  updatedAt
                  dataSourceNotificationActionsId
                  __typename
                }
                nextToken
                __typename
              }
              integrations {
                items {
                  id
                  type
                  dataProvider
                  integrationCredentialId
                  integrationDataSourceId
                  createdAt
                  updatedAt
                  composerQueryIntegrationsId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              dataFreshness
              filter {
                isActive
                dimensionsFilters {
                  fieldName
                  values
                  type
                  operator
                  __typename
                }
                segment {
                  id
                  name
                  __typename
                }
                __typename
              }
              version
              rateLimit {
                max
                interval
                __typename
              }
              dataSourceCredentialId
              credential {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              dataSourceTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              labels {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              groups
              __typename
            }
            nextToken
            __typename
          }
          credentials {
            items {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          dataSourceLabels {
            items {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultTemplateId
          workspaceType
          createdAt
          updatedAt
          __typename
        }
        dataSources {
          items {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                queryTeamId
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          nextToken
          __typename
        }
        dataProvider
        groups
        label {
          id
          name
          nameLowercase
          value
          type
          dataSourceLabelTeamId
          team {
            id
            avatar {
              visibility
              identityId
              file {
                bucket
                key
                region
                fileName
                __typename
              }
              __typename
            }
            name
            owner
            members
            admins
            editors
            emailDomains
            autoInviteDomains
            teamMembers {
              items {
                id
                teamMembershipUserId
                user {
                  id
                  username
                  userType
                  defaultTeamId
                  createdAt
                  updatedAt
                  __typename
                }
                teamMembershipTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                owner
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            credentials {
              items {
                id
                name
                email
                picture
                status
                owners
                editors
                createdAt
                updatedAt
                credentialTeamId
                authType
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                dataProvider
                groups
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                credentialLabelId
                authDataId
                clientId
                authData {
                  accessToken
                  refreshToken
                  scope
                  tokenType
                  idToken
                  expiryDate
                  username
                  password
                  token
                  key
                  environment
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            dataSourceLabels {
              items {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            defaultTemplateId
            workspaceType
            createdAt
            updatedAt
            __typename
          }
          createdAt
          dataProviders
          dataSources {
            items {
              id
              dataSourceID
              labelID
              dataSource {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              dataSourceLabelDataSourcesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        credentialLabelId
        authDataId
        clientId
        authData {
          accessToken
          refreshToken
          scope
          tokenType
          idToken
          expiryDate
          username
          password
          token
          key
          environment
          __typename
        }
        __typename
      }
      dataSourceTeamId
      team {
        id
        avatar {
          visibility
          identityId
          file {
            bucket
            key
            region
            fileName
            __typename
          }
          __typename
        }
        name
        owner
        members
        admins
        editors
        emailDomains
        autoInviteDomains
        teamMembers {
          items {
            id
            teamMembershipUserId
            user {
              id
              username
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              userType
              defaultTeamId
              userSettings {
                onboardingCompleted
                releaseNotes
                __typename
              }
              teams {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            teamMembershipTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            owner
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dataSources {
          items {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                queryTeamId
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          nextToken
          __typename
        }
        credentials {
          items {
            id
            name
            email
            picture
            status
            owners
            editors
            createdAt
            updatedAt
            credentialTeamId
            authType
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            dataSources {
              items {
                id
                owner
                editors
                admins
                createdAt
                updatedAt
                dataProvider
                displayName
                displayNameLowercase
                configParamsHash
                name
                nameLowercase
                tags
                tagsLowercase
                tagsLowercaseToString
                url
                hostname
                domain
                type
                view {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                multipleViews {
                  viewId
                  viewName
                  propertyId
                  websiteId
                  loginCustomerId
                  customerId
                  site
                  locationId
                  channelId
                  spreadsheetId
                  accountId
                  projectId
                  dc
                  connectorId
                  dataSourceIds
                  credentialIds
                  merchantId
                  isManager
                  timeZone
                  reportType
                  url
                  device
                  type
                  dataSourceId
                  credentialId
                  __typename
                }
                dataUrls {
                  nextToken
                  __typename
                }
                queries {
                  nextToken
                  __typename
                }
                preCacheRequests {
                  nextToken
                  __typename
                }
                notificationActions {
                  nextToken
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                dataFreshness
                filter {
                  isActive
                  __typename
                }
                version
                rateLimit {
                  max
                  interval
                  __typename
                }
                dataSourceCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                dataSourceTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                labels {
                  nextToken
                  __typename
                }
                groups
                __typename
              }
              nextToken
              __typename
            }
            dataProvider
            groups
            label {
              id
              name
              nameLowercase
              value
              type
              dataSourceLabelTeamId
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              createdAt
              dataProviders
              dataSources {
                items {
                  id
                  dataSourceID
                  labelID
                  createdAt
                  updatedAt
                  dataSourceLabelDataSourcesId
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              updatedAt
              owner
              __typename
            }
            credentialLabelId
            authDataId
            clientId
            authData {
              accessToken
              refreshToken
              scope
              tokenType
              idToken
              expiryDate
              username
              password
              token
              key
              environment
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        dataSourceLabels {
          items {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        defaultTemplateId
        workspaceType
        createdAt
        updatedAt
        __typename
      }
      labels {
        items {
          id
          dataSourceID
          labelID
          dataSource {
            id
            owner
            editors
            admins
            createdAt
            updatedAt
            dataProvider
            displayName
            displayNameLowercase
            configParamsHash
            name
            nameLowercase
            tags
            tagsLowercase
            tagsLowercaseToString
            url
            hostname
            domain
            type
            view {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            multipleViews {
              viewId
              viewName
              propertyId
              websiteId
              loginCustomerId
              customerId
              site
              locationId
              channelId
              spreadsheetId
              accountId
              projectId
              dc
              connectorId
              dataSourceIds
              credentialIds
              merchantId
              isManager
              timeZone
              reportType
              url
              device
              type
              dataSourceId
              credentialId
              __typename
            }
            dataUrls {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                createdAt
                updatedAt
                dataSourceDataUrlsId
                __typename
              }
              nextToken
              __typename
            }
            queries {
              items {
                id
                owner
                groups
                name
                nameLowercase
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                additionalFields {
                  name
                  value
                  __typename
                }
                integrations {
                  nextToken
                  __typename
                }
                queryTeamId
                createdAt
                updatedAt
                dataSourceQueriesId
                __typename
              }
              nextToken
              __typename
            }
            preCacheRequests {
              items {
                id
                name
                nameLowercase
                fields
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                requestCount
                query
                TTL
                updatedAt
                createdAt
                dataSourcePreCacheRequestsId
                __typename
              }
              nextToken
              __typename
            }
            notificationActions {
              items {
                id
                owner
                groups
                name
                nameLowercase
                status
                notificationService
                template
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                limit
                fields
                sort {
                  id
                  sortDirection
                  __typename
                }
                dimensionsFilters {
                  __typename
                }
                dateRangeType
                dateRange {
                  startDate
                  endDate
                  __typename
                }
                schedule
                trigger
                notificationActionCredentialId
                createdAt
                updatedAt
                dataSourceNotificationActionsId
                __typename
              }
              nextToken
              __typename
            }
            integrations {
              items {
                id
                type
                dataProvider
                additionalSettings {
                  name
                  value
                  __typename
                }
                query {
                  id
                  owner
                  groups
                  name
                  nameLowercase
                  limit
                  fields
                  dateRangeType
                  queryTeamId
                  createdAt
                  updatedAt
                  dataSourceQueriesId
                  __typename
                }
                integrationCredentialId
                credential {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                integrationDataSourceId
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                createdAt
                updatedAt
                composerQueryIntegrationsId
                owner
                __typename
              }
              nextToken
              __typename
            }
            dataFreshness
            filter {
              isActive
              dimensionsFilters {
                fieldName
                values
                type
                operator
                __typename
              }
              segment {
                id
                name
                __typename
              }
              __typename
            }
            version
            rateLimit {
              max
              interval
              __typename
            }
            dataSourceCredentialId
            credential {
              id
              name
              email
              picture
              status
              owners
              editors
              createdAt
              updatedAt
              credentialTeamId
              authType
              team {
                id
                avatar {
                  visibility
                  identityId
                  __typename
                }
                name
                owner
                members
                admins
                editors
                emailDomains
                autoInviteDomains
                teamMembers {
                  nextToken
                  __typename
                }
                dataSources {
                  nextToken
                  __typename
                }
                credentials {
                  nextToken
                  __typename
                }
                dataSourceLabels {
                  nextToken
                  __typename
                }
                defaultTemplateId
                workspaceType
                createdAt
                updatedAt
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              dataProvider
              groups
              label {
                id
                name
                nameLowercase
                value
                type
                dataSourceLabelTeamId
                team {
                  id
                  name
                  owner
                  members
                  admins
                  editors
                  emailDomains
                  autoInviteDomains
                  defaultTemplateId
                  workspaceType
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                dataProviders
                dataSources {
                  nextToken
                  __typename
                }
                updatedAt
                owner
                __typename
              }
              credentialLabelId
              authDataId
              clientId
              authData {
                accessToken
                refreshToken
                scope
                tokenType
                idToken
                expiryDate
                username
                password
                token
                key
                environment
                __typename
              }
              __typename
            }
            dataSourceTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            labels {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            groups
            __typename
          }
          label {
            id
            name
            nameLowercase
            value
            type
            dataSourceLabelTeamId
            team {
              id
              avatar {
                visibility
                identityId
                file {
                  bucket
                  key
                  region
                  fileName
                  __typename
                }
                __typename
              }
              name
              owner
              members
              admins
              editors
              emailDomains
              autoInviteDomains
              teamMembers {
                items {
                  id
                  teamMembershipUserId
                  teamMembershipTeamId
                  owner
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              dataSources {
                items {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                nextToken
                __typename
              }
              credentials {
                items {
                  id
                  name
                  email
                  picture
                  status
                  owners
                  editors
                  createdAt
                  updatedAt
                  credentialTeamId
                  authType
                  dataProvider
                  groups
                  credentialLabelId
                  authDataId
                  clientId
                  __typename
                }
                nextToken
                __typename
              }
              dataSourceLabels {
                items {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                nextToken
                __typename
              }
              defaultTemplateId
              workspaceType
              createdAt
              updatedAt
              __typename
            }
            createdAt
            dataProviders
            dataSources {
              items {
                id
                dataSourceID
                labelID
                dataSource {
                  id
                  owner
                  editors
                  admins
                  createdAt
                  updatedAt
                  dataProvider
                  displayName
                  displayNameLowercase
                  configParamsHash
                  name
                  nameLowercase
                  tags
                  tagsLowercase
                  tagsLowercaseToString
                  url
                  hostname
                  domain
                  type
                  dataFreshness
                  version
                  dataSourceCredentialId
                  dataSourceTeamId
                  groups
                  __typename
                }
                label {
                  id
                  name
                  nameLowercase
                  value
                  type
                  dataSourceLabelTeamId
                  createdAt
                  dataProviders
                  updatedAt
                  owner
                  __typename
                }
                createdAt
                updatedAt
                dataSourceLabelDataSourcesId
                owner
                __typename
              }
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          dataSourceLabelDataSourcesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      groups
      __typename
    }
  }
`;
