import { Authenticator, translations } from '@aws-amplify/ui-react';

import { CssBaseline } from '@mui/material';
import {
  createTheme,
  responsiveFontSizes,
  styled,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Amplify, Auth, Hub, I18n } from 'aws-amplify';
import JavascriptTimeAgo from 'javascript-time-ago';
import nl from 'javascript-time-ago/locale/nl';
import { SnackbarProvider } from 'notistack';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import './amplify.css';
import awsconfig from './aws-exports-static';
import AuthDataProvider from './components/AuthDataProvider';
import CreateReportAuth from './components/createReport/CreateReportAuth';
import AddNewDashboardDialog from './components/dashboards/AddNewDashboardDialog';
import CreateNewDataSourceDialog from './components/data/CreateNewDataSourceDialog';
import DataSourceOverviewList from './components/data/DataSourceOverviewList';
import EditDataSourceDialog from './components/data/EditDataSourceDialog';
import ReAuthenticateCredentialDialog from './components/data/ReAuthenticateCredentialDialog';
import RequireAuth from './components/RequireAuth';
import AppWithAuth from './components/router/AppWithAuth';
import ConfirmResetPassword from './components/router/ConfirmResetPassword';
import LocalhostRoute from './components/router/LocalhostRoute';
import MajesticUsercallbackRoute from './components/router/MajesticUsercallbackRoute';
import UsercallbackRoute from './components/router/UsercallbackRoute';
import ManageCredentialsDialog from './components/teams/ManageCredentialsDialog';
import ManageTeamMembersDialog from './components/teams/ManageTeamMembersDialog';
import CreateNewTemplateDialog from './components/templates/CreateNewTemplateDialog';
import EditTemplateDialog from './components/templates/EditTemplateDialog';
import TemplatesOverviewList from './components/templates/TemplatesOverviewList';
import Authorization from './containers/Authorization';
import MagentoAuthorization from './containers/MagentoAuthorization';
import MiniConnect from './containers/MiniConnect';
import Root from './containers/Root';
import Success from './containers/Success';
import ValidateWorkspace from './containers/ValidateWorkspace';
import Workspace from './containers/Workspace';
import WorkspacesOverview from './containers/WorkspacesOverview';
import './index.css';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';

// Force deployment

I18n.putVocabularies(translations);
I18n.setLanguage('en');

I18n.putVocabulariesForLanguage('en', {
  'Forgot your password?': 'Forgot or Reset Password',
  'Password did not conform with policy: Password not long enough': `Password is too short
    Make sure it's at least 8 characters.`,
});

JavascriptTimeAgo.locale(nl);

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [localRedirectSignIn, productionRedirectSignIn] =
  awsconfig.oauth.redirectSignIn.split(',');

const [localRedirectSignOut, productionRedirectSignOut] =
  awsconfig.oauth.redirectSignOut.split(',');

const updatedAwsConfig = {
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  },
};
delete awsconfig.oauth;
Amplify.configure(awsconfig);

Auth.configure(updatedAwsConfig);

const dataProviderEndpoint =
  process.env.REACT_APP_USER_BRANCH === 'prod'
    ? 'https://data-provider-api.dh.gladior.com'
    : 'https://data-provider-api-dev.dh.gladior.com';

const backendEndpoint =
  process.env.REACT_APP_USER_BRANCH === 'prod'
    ? 'https://data-hub-api.dh.gladior.com'
    : 'https://data-hub-api-dev.dh.gladior.com';

Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'DataProviderApi',
        endpoint: dataProviderEndpoint,
        custom_header: async () => ({
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        }),
      },
      {
        name: 'BackendApi',
        endpoint: backendEndpoint,
        custom_header: async () => ({
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        }),
      },
    ],
  },
});

Amplify.Logger.LOG_LEVEL = 'INFO';

const store = configureStore();
// const muiBaseTheme = responsiveFontSizes(generateMuiBaseTheme());

const theme = createTheme();

const outerTheme = createTheme({
  palette: {
    // mode: 'dark',
    background: {
      default: '#F8F9FA',
    },
    primary: {
      main: '#20252C',
    },
    secondary: {
      main: '#e0257b',
    },
  },

  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        sizeLarge: {
          padding: '1rem 1.25rem',
          ...theme.typography.body1,
          lineHeight: 21 / 16,
          fontWeight: 700,
        },
        containedPrimary: {
          backgroundColor: theme.palette.primary[500],
          color: '#fff',
        },
      },
      variants: [
        {
          props: { variant: 'action' },
          style: {
            ...theme.components.MuiButton,
            color: theme.palette.info.contrastText,
            padding: theme.spacing(1, 2),
            borderRadius: theme.shape.borderRadius * 2,
            fontWeight: '600',

            backgroundColor: theme.palette.info.main,
            textTransform: 'none',

            '&:hover, &.Mui-focusVisible': {
              backgroundColor: theme.palette.info.dark,
              '& .MuiButton-endIcon': {
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.primary[300]
                    : theme.palette.primary.main,
              },
            },
            '& .MuiButton-startIcon': {
              color: theme.palette.grey[400],
            },
            '& .MuiButton-endIcon': {
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.grey[400]
                  : theme.palette.grey[700],
            },
          },
        },
      ],
    },
  },
});

const Snackbar = styled(SnackbarProvider)`
  &.SnackbarItem-variantError {
    background-color: #e0257b;
  }
`;

function HubListener() {
  const navigate = useNavigate();

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      // eslint-disable-next-line default-case
      switch (event) {
        case 'customOAuthState':
          if (JSON.parse(data).location) {
            navigate(JSON.parse(data).location);
          }
          // go to route
          break;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Navigate replace to="/workspace" />;
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <Authenticator.Provider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={responsiveFontSizes(outerTheme)}>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              maxSnack={3}
            >
              <CssBaseline />
              <BrowserRouter>
                <Routes>
                  <Route element={<Success />} path="/success" />
                  <Route
                    element={<ConfirmResetPassword />}
                    path="confirm-new-password/:code/:token"
                  />
                  <Route element={<AppWithAuth />} path="/auth">
                    <Route element={<AppWithAuth />} path="sign-in" />
                    <Route element={<AppWithAuth />} path="sign-up" />
                    <Route element={<AppWithAuth />} path="reset-password" />
                    <Route
                      element={<AppWithAuth />}
                      path="confirm-reset-password"
                    />
                  </Route>
                  <Route element={<LocalhostRoute />} path="/local" />
                  <Route element={<UsercallbackRoute />} path="/usercallback" />
                  <Route
                    element={<MajesticUsercallbackRoute />}
                    path="/usercallback-majestic"
                  />
                  <Route
                    element={
                      <RequireAuth redirectTo="/auth">
                        <Root />
                      </RequireAuth>
                    }
                    path="/workspace"
                  >
                    <Route element={<WorkspacesOverview />} index />
                    <Route
                      element={
                        <ValidateWorkspace>
                          <Workspace />
                        </ValidateWorkspace>
                      }
                      path=":workspaceId"
                    >
                      <Route
                        element={<Navigate replace to="data-sources" />}
                        index
                      />
                      <Route
                        element={<DataSourceOverviewList />}
                        path="data-sources"
                      >
                        <Route
                          element={<ManageTeamMembersDialog />}
                          path="members"
                        />

                        <Route
                          element={<ReAuthenticateCredentialDialog />}
                          path="re-authenticate"
                        />

                        <Route
                          element={<ManageCredentialsDialog />}
                          path="credentials"
                        />
                        <Route
                          element={<EditDataSourceDialog />}
                          path="d/:dataSourceId"
                        />

                        <Route
                          element={<CreateNewDataSourceDialog />}
                          path="create/data-source"
                        />
                        <Route
                          element={
                            <CreateNewDataSourceDialog integration={true} />
                          }
                          path="create/integration"
                        />
                        {/* <Route element={<AddNewSetDialog />} path="create/set" /> */}
                        <Route
                          element={<AddNewDashboardDialog />}
                          path="create/dashboard"
                        />
                      </Route>

                      <Route
                        element={<TemplatesOverviewList />}
                        path="templates"
                      >
                        <Route
                          element={<CreateNewTemplateDialog />}
                          path="create/template"
                        />

                        <Route
                          element={<AddNewDashboardDialog />}
                          path="create/dashboard"
                        />

                        <Route
                          element={<EditTemplateDialog />}
                          path="t/:templateId"
                        />
                      </Route>

                      <Route
                        element={<AuthDataProvider />}
                        path="auth/:dataProvider"
                      />
                    </Route>
                  </Route>
                  <Route
                    element={<MiniConnect />}
                    path="connect/:dataProvider"
                  />

                  <Route
                    element={<Authorization />}
                    path="oauth/authorization"
                  />
                  <Route
                    element={<MagentoAuthorization />}
                    path=":dataProvider/authorization"
                  />

                  <Route element={<CreateReportAuth />} path="/createreport" />

                  <Route element={<HubListener />} path="/" />
                </Routes>
              </BrowserRouter>
            </Snackbar>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </Authenticator.Provider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
