/* eslint-disable no-nested-ternary */
import { Error } from '@mui/icons-material';
import FiberNewOutlined from '@mui/icons-material/FiberNewOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import { makeStyles, useTheme } from '@mui/styles';
import querystring from 'querystring';
import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ellipsis from 'text-ellipsis';
import * as types from '../../constants/ActionTypes';
import * as dp from '../../constants/DataProviders';
import dataSetIcon from '../../data-set-icon.svg';
import dataSourceIcon from '../../data-source-icon.svg';
import ReactJoin from '../../utilities/react-join';
import DataProviderChip from './DataProviderChip';

const useStyles = makeStyles((theme) => ({
  inline: {
    display: 'inline',
  },
  tableCellNoBorder: {
    border: 'none',
    padding: '8px 24px 8px 16px',
  },
  tableRow: {
    cursor: 'pointer',
  },
  dataSourceIconAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

function DataSourceTableRow({
  item,
  userObject,
  setMenuAnchorEl,
  setOpenChapterMenuId,
  menuAnchorEl,
  openChapterMenuId,
  showAccessLevel = true,
  showIcon = true,
  showDataProvider = true,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const currentAuthenticatedUser = useSelector(
    (state) => state.currentAuthenticatedUser
  );
  const groups =
    currentAuthenticatedUser.signInUserSession.accessToken.payload[
      'cognito:groups'
    ];

  const selectedUserTeam = useSelector((state) => state.selectedUserTeam);

  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if (item.status && item.status === 'new') {
      setTimeout(() => {
        setIsNew(true);
      }, 500);

      setTimeout(() => {
        setIsNew(false);
      }, 3000);
    }
  }, [item]);

  let hasEditRights = userObject.id === item.owner;
  const isOwner = userObject.id === item.owner;

  if (hasEditRights === false) {
    if (groups) {
      if (groups.includes(item.groups)) {
        hasEditRights = true;
      }
    }

    if (item.editors) {
      if (item.editors.includes(userObject.id)) {
        hasEditRights = true;
      }
    }
  }

  function closeMenu() {
    setMenuAnchorEl(null);
    setOpenChapterMenuId(-1);
  }

  return (
    <TableRow
      className={classes.tableRow}
      hover
      key={item.id}
      sx={(theme1) => ({
        transition: `${theme1.transitions.create(
          ['background-color', 'transform'],
          {
            duration: theme1.transitions.duration.standard,
          }
        )}`,
        backgroundColor: isNew ? 'rgb(229, 246, 253)' : 'inherit',
      })}
    >
      <TableCell
        className={classes.tableCellNoBorder}
        onClick={() => {
          const queryObj = querystring.parse(location.search.slice(1));

          navigate({
            pathname: `/workspace/${
              selectedUserTeam.split('-')[0]
            }/data-sources/d/${item.id}`,
            search: location.search ? `${querystring.stringify(queryObj)}` : '',
          });
        }}
      >
        <Box display="flex" flexDirection="row">
          {showIcon === true && (
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              marginRight="12px"
            >
              {!item.credential &&
              item.dataProvider !== dp.MULTI_DATA_PROVIDER ? (
                <Error htmlColor={red[500]} />
              ) : (
                <Tooltip title="">
                  <>
                    {isNew ? (
                      <FiberNewOutlined
                        htmlColor={theme.palette.primary.main}
                      />
                    ) : (
                      <Avatar
                        alt=""
                        className={classes.dataSourceIconAvatar}
                        src={
                          item.dataProvider === dp.MULTI_DATA_PROVIDER
                            ? dataSetIcon
                            : item.purpose === 'INTEGRATION'
                              ? dataSourceIcon
                              : dataSourceIcon
                        }
                        variant="square"
                      />
                    )}
                  </>
                </Tooltip>
              )}
            </Box>
          )}

          <Box display="flex" flexDirection="column">
            <Typography
              className={classes.inline}
              color="textPrimary"
              component="span"
              variant="body1"
            >
              {ellipsis(item.displayName ? item.displayName : item.name, 50)}
            </Typography>
            <Typography
              className={classes.inline}
              color="textSecondary"
              component="span"
              variant="body2"
            >
              <ReactJoin
                separator={
                  <Icon
                    style={{
                      fontSize: 'inherit',
                      lineHeight: 'inherit',
                    }}
                  >
                    navigate_next
                  </Icon>
                }
              >
                {item.tags.map((tag) => ellipsis(tag, 40))}
              </ReactJoin>
            </Typography>
          </Box>
        </Box>
      </TableCell>

      {showDataProvider === true && (
        <TableCell
          className={classes.tableCellNoBorder}
          onClick={() => {
            navigate(
              `/workspace/${selectedUserTeam.split('-')[0]}/data-sources/d/${
                item.id
              }`
            );
          }}
        >
          <DataProviderChip
            dataProvider={item.dataProvider}
            variant={showIcon === false ? 'avatar' : 'chip'}
          />
        </TableCell>
      )}

      {showAccessLevel === true && groups.includes('gladior') && (
        <TableCell
          className={classes.tableCellNoBorder}
          onClick={() => {
            navigate(
              `/workspace/${selectedUserTeam.split('-')[0]}/data-sources/d/${
                item.id
              }`
            );
          }}
        >
          <Typography
            className={classes.inline}
            color="textSecondary"
            component="span"
            variant="body2"
          >
            {isOwner ? 'Owner' : hasEditRights ? 'Editor' : 'Viewer'}
          </Typography>
        </TableCell>
      )}

      <TableCell align="right" className={classes.tableCellNoBorder}>
        <IconButton
          onClick={(event) => {
            setMenuAnchorEl(event.currentTarget);
            setOpenChapterMenuId(item.id);
          }}
          size="large"
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          PaperProps={{
            style: {
              width: 250,
            },
          }}
          anchorEl={menuAnchorEl}
          id="long-menu"
          keepMounted
          onClose={() => {
            setMenuAnchorEl(null);
            setOpenChapterMenuId(-1);
          }}
          open={openChapterMenuId === item.id}
        >
          <MenuItem
            onClick={() => {
              navigate(
                `/workspace/${selectedUserTeam.split('-')[0]}/data-sources/d/${
                  item.id
                }`
              );
              closeMenu();
            }}
          >
            Open settings
          </MenuItem>

          <MenuItem
            disabled={!hasEditRights}
            onClick={() => {
              batch(() => {
                dispatch({
                  type: types.SET_SELECTED_DATA_SOURCE,
                  dataSource: item,
                });

                dispatch({
                  type: types.SHOW_GOOGLE_DATA_STUDIO_DIALOG,
                  isVisible: true,
                });
              });

              closeMenu();
            }}
          >
            Looker Studio
          </MenuItem>

          <MenuItem
            disabled={!hasEditRights}
            onClick={() => {
              batch(() => {
                dispatch({
                  type: types.SET_SELECTED_DATA_SOURCE,
                  dataSource: item,
                });

                dispatch({
                  type: types.SHOW_RE_CONNECT_DATA_SOURCE_DIALOG,
                  isVisible: true,
                });
              });

              closeMenu();
            }}
          >
            Reconnect data source
          </MenuItem>
          {groups.includes('gladior') && (
            <MenuItem
              disabled={!hasEditRights}
              onClick={() => {
                batch(() => {
                  dispatch({
                    type: types.SET_SELECTED_DATA_SOURCE,
                    dataSource: item,
                  });

                  dispatch({
                    type: types.SHOW_EDITING_RIGHTS_DATA_SOURCE_DIALOG,
                    isVisible: true,
                  });
                });

                closeMenu();
              }}
            >
              Permissions and Roles
            </MenuItem>
          )}
          <MenuItem
            disabled={!hasEditRights}
            onClick={() => {
              batch(() => {
                dispatch({
                  type: types.SET_SELECTED_DATA_SOURCE,
                  dataSource: item,
                });

                dispatch({
                  type: types.SHOW_DELETE_DATA_SOURCE_DIALOG,
                  isVisible: true,
                });
              });

              closeMenu();
            }}
          >
            Delete data source
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
}

export default DataSourceTableRow;
