/* eslint-disable no-nested-ternary */

import {
  Abc,
  CalendarMonth,
  Dataset,
  InfoOutlined,
  LocationOn,
  Pin,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Chip,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { blue, green, grey } from '@mui/material/colors';

import _sortBy from 'lodash/sortBy';
import React from 'react';
import 'react-multi-carousel/lib/styles.css';

const supportedIconsForType = [
  'NUMBER',
  'TEXT',
  'YEAR_MONTH_DAY',
  'LATITUDE_LONGITUDE',
];

function FieldsAutocompleteSelector({
  value,
  setValue,
  label,
  fieldOptions,
  isLoading,
  multiple = true,
  disableClearable = false,
}) {
  const [inputValue, setInputValue] = React.useState('');

  const sortedFields = _sortBy(fieldOptions, [
    function (o) {
      return o.group;
    },
  ]);

  return (
    <>
      <Autocomplete
        disableClearable={disableClearable}
        getOptionLabel={(option) => option.name}
        groupBy={(option) => option.group}
        inputValue={inputValue}
        loading={isLoading}
        multiple={multiple}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={sortedFields}
        renderGroup={(params) => (
          <Box key={params.group}>
            <Typography color="textPrimary" sx={{ ml: 1 }} variant="caption">
              <b>{params.group}</b>
            </Typography>

            {params.children}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            size="small"
            variant="outlined"
          />
        )}
        renderOption={(props, option) => {
          let muiColor;

          if (option.conceptType === 'DIMENSION') {
            muiColor = green;
          } else {
            muiColor = blue;
          }

          return (
            <Box component="li" {...props} key={option.id}>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={1}
                sx={{ width: '100%' }}
              >
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="center"
                  spacing={1}
                >
                  {option.type === 'NUMBER' && (
                    <Pin htmlColor={muiColor[400]} />
                  )}
                  {option.type === 'TEXT' && <Abc htmlColor={muiColor[400]} />}

                  {option.type === 'YEAR_MONTH_DAY' && (
                    <CalendarMonth fontSize="small" htmlColor={muiColor[400]} />
                  )}

                  {option.type === 'LATITUDE_LONGITUDE' && (
                    <LocationOn fontSize="small" htmlColor={muiColor[400]} />
                  )}

                  {!supportedIconsForType.includes(option.type) && (
                    <Dataset fontSize="small" htmlColor={muiColor[400]} />
                  )}

                  <Box>
                    <Typography variant="caption">{option.name}</Typography>
                  </Box>
                </Stack>

                <Box>
                  <Tooltip
                    title={
                      option.description ? option.description : option.name
                    }
                  >
                    <InfoOutlined fontSize="small" htmlColor={grey[600]} />
                  </Tooltip>
                </Box>
              </Stack>
            </Box>
          );
        }}
        renderTags={(value2, getTagProps) =>
          value2.map((option, index) => {
            let muiColor;

            if (option.conceptType === 'DIMENSION') {
              muiColor = green;
            } else {
              muiColor = blue;
            }

            return (
              <Chip
                key={option.id}
                label={option.name}
                size="small"
                sx={{ bgcolor: muiColor[100] }}
                variant="filled"
                {...getTagProps({ index })}
              />
            );
          })
        }
        value={value}
      />
    </>
  );
}

export default FieldsAutocompleteSelector;
